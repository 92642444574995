<template>
  <div>
    <v-list-item :to="{ name: 'clientNotices' }">
      <v-list-item-icon>
        <img height="20" width="20" src="@/assets/icons/agenda.svg" alt="Dashboard" />
      </v-list-item-icon>
      <v-list-item-title>Avisos</v-list-item-title>
    </v-list-item>

    <v-list-group v-model="clients" :group="clientMenuGroup" no-action>
      <template v-slot:activator>
        <v-list-item-icon>
          <img src="@/assets/icons/clients.svg" alt="clients" />
        </v-list-item-icon>
        <v-list-item-title>Clientes</v-list-item-title>
      </template>

      <v-list-item :to="{ name: 'clients' }">
        <v-list-item-title>Lista de clientes</v-list-item-title>
      </v-list-item>

      <v-list-item :to="{ name: 'centers' }">
        <v-list-item-title>Centros</v-list-item-title>
      </v-list-item>

      <v-list-item :to="{ name: 'businessGroups' }">
        <v-list-item-title>Grupos empresariales</v-list-item-title>
      </v-list-item>
    </v-list-group>

    <v-list-item :to="{ name: 'trainingCourses' }">
      <v-list-item-icon>
        <img src="@/assets/icons/training-courses.svg" alt="Formaciones" />
      </v-list-item-icon>
      <v-list-item-title>Formaciones</v-list-item-title>
    </v-list-item>

    <v-list-item :to="{ name: 'students' }">
      <v-list-item-icon>
        <img src="@/assets/icons/people.svg" alt="Alumnos" />
      </v-list-item-icon>
      <v-list-item-title>Alumnos</v-list-item-title>
    </v-list-item>

    <v-list-item :to="{ name: 'contacts' }">
      <v-list-item-icon>
        <img src="@/assets/icons/contacts.svg" alt="Contactos" />
      </v-list-item-icon>
      <v-list-item-title>Contactos</v-list-item-title>
    </v-list-item>

    <v-list-group v-model="settings" group="settings" no-action>
      <template v-slot:activator>
        <v-list-item-icon>
          <img src="@/assets/icons/settings.svg" alt="settings" />
        </v-list-item-icon>
        <v-list-item-title>Configuraciones</v-list-item-title>
      </template>

      <v-list-item :to="{ name: 'organizationalCompanies' }">
        <v-list-item-title>Entidades Organizativas</v-list-item-title>
      </v-list-item>

      <v-list-item :to="{ name: 'trainers' }">
        <v-list-item-title>Formadores</v-list-item-title>
      </v-list-item>

      <v-list-item :to="{ name: 'directors' }">
        <v-list-item-title>Directores</v-list-item-title>
      </v-list-item>

      <v-list-item :to="{ name: 'trainingCourseCategory' }">
        <v-list-item-title>Tipo de formaciones</v-list-item-title>
      </v-list-item>

      <v-list-item :to="{ name: 'localizations' }">
        <v-list-item-title>Localizaciones</v-list-item-title>
      </v-list-item>

      <v-list-item :to="{ name: 'countryRegions' }">
        <v-list-item-title>CCAA</v-list-item-title>
      </v-list-item>

      <v-list-item :to="{ name: 'documentTemplates' }">
        <v-list-item-title>Plantillas docs.</v-list-item-title>
      </v-list-item>

      <v-list-item :to="{ name: 'emailTemplates' }">
        <v-list-item-title>Plantillas email</v-list-item-title>
      </v-list-item>
    </v-list-group>

    <v-divider class="my-2"></v-divider>

    <v-list-item :to="{ name: 'users' }">
      <v-list-item-icon>
        <img src="@/assets/icons/people.svg" alt="settings" />
      </v-list-item-icon>
      <v-list-item-title>Usuarios</v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
export default {
  data: () => ({
    settings: null,
    clients: null,
    stats: null,
    clientMenuGroup: "clients|centers|business-groups",
  }),
};
</script>

<style scoped></style>
